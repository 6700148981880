.list-set {
  font-weight: bold;
  margin-right: 10px;
  font-size: large;
  color: darkgray;
}
.list-data {
  font-size: larger;
}
.address {
  margin-left: 95px;
}
