@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'),
    url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2)
      format('woff2');
  font-display: swap;
}
* {
  font-family: 'Mrs Eaves XL Serif', serif;
}
.head-contact {
  background: #f9f8f2;
  font-family: 'robotoregular';
  position: relative;
  font-size: 14px;
}
.head-main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
li {
  list-style: none;
}
.contact-us * {
  text-decoration: none;
  color: #4a4a4a;
}
.head-mid {
  padding-top: 30px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.btn-search {
  position: absolute;
}
.icons {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #4a4a4a;
  text-decoration: none;
}
.formwidth {
  width: 30vw;
}
.lowlist {
  display: flex;
  justify-content: space-evenly;
  font-size: 0.875rem;
}
.lowlist * {
  padding-right: 5px;
  color: #4a4a4a;
}
.logomid {
  width: 60px;
  height: 60px;
}
a {
  list-style: none;
  text-decoration: none;
}
a:hover {
  color: orange;
}
.icons:hover {
  color: orange;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
.iconvarywidth {
  width: 30px;
  height: 25px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footer-top {
  background: #fff;
  padding: 10px;
  text-align: justify;
  letter-spacing: 0.015em;
  margin-bottom: 20px;
}
.footer-top > p {
  line-height: 20px;
  margin: 0 0 12px;
  color: #8b8b8b;
  font-family: 'pangramregular';
}
.footer-top > h5 {
  font-family: 'pangrammedium';
  color: #5f5e5e;
  line-height: 23px;
}
.tech-slideshow {
  height: 140px;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  margin-top: 20px;
}

.tech-slideshow > div {
  height: 200px;
  width: 2526px;
  background: url(https://lh3.googleusercontent.com/pw/AL9nZEXXNpGnpzUlGmYqpg05iLRqoQlpcjWXbip2nrvAFNn6zCuL1yeWi1Ng5jqMFTK4zhPZA7ZRp5yQZPMEJa7yn-MTa9SGtHo-8kYKMqHMwWYsPz8aJXDMMHe970fkzndgOOKLUvfoaNp-b_R4dLB5ctZ2BKhDfhiI9IUE9zNek3NGlx_-bi4sOz_7-XarNdHKsJVMlUzOsXsSLD8ioBW96hLP3bW_Y6Y9kyFJ9wXvKSOuZZueyCp_al1kyOYRvVkS0iB3R_9-lSK2JnI4IMls6lrGBQvSX3wq0kevHh6e5X6LPOpnuysRQT9gUYPPKmFCYGkRq-x9nJiuVRam0CBVR0YsjkTwQy5NCkWFtJ4iN_fK2iSEtWQ7Gv21GN264JaYR8fTp0BKRIdFRs1Axe_wr58EHGdclwmYYG05ip3DC3th9BMUm2q88hyQQCMIU3PC-4ViOXAZzcCt_BAE2nPs-2KOZ3bRuM4cj0jsAUrRJeyhSnT4gz6SpK5zCYuj6b0dCNnfp-bypG411w3PoyCPLMIJ7EGeFK2RXYE-e7UYXWz5c8T_nkjGZ-DnLTRy2KdqMLvNkSWN3rtevzGLbZG0hhgDL_Khq_HJfnnFCjLeg5J34h2q7JPUPleZoUYynWn4-16nKbOKwxnzR5pdKxQrSH_-NssIg0RgImoGu1TCYmY7v6091WBzdStES7_R8Y4_cmQXq3QA8Vvx-P8fr1aIXhLK5EjtI8rWemanN7vTMjHp5SF90Vh7i_iQ-DaD7i6a_G6njymUyULkcvEiC4WaEvgnojMF0ajHjISXWwi6URIL7DJc22C-B69ZrOd4PyT21hxXMq1f55aspFU8NPDouTYF0ZR1HALjeyspH_xOKqtB6srPAfDbHNi0ICoK-dKqeAShuQvIi4ruTFR_--mYA4qs5Ug5foPkID4hv3GmYBHKIkg3IcoKOyyZ9uFu=w958-h152-no?authuser=0);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transform: translate3d(0, 0, 0);
}
.tech-slideshow .mover-1 {
  animation: moveSlideshow 12s linear infinite;
}
.tech-slideshow .mover-2 {
  opacity: 1;
  transition: opacity 0.5s ease-out;
  background-position: 0 -200px;
  animation: moveSlideshow 16s linear infinite;
}
.tech-slideshow:hover .mover-2 {
  opacity: 0;
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-66.6666%);
  }
}
.footer-mid {
  background: #f5f5f5;
}
.mid-align {
  padding-top: 20px;
  text-align: justify;
  line-height: 20px;
  color: #464545;
  font-family: 'pangramregular';
}
#footerPaymentTypes * {
  margin: 5px;
}
.formmobilewidth {
  width: 100%;
}
.sidebar-nav * {
  line-height: 40px;
}
.mobile-headall {
  background-color: #f5f5f5;
}
.mobile-headall * {
  color: #4a4a4a;
}

.main * {
  line-height: 35px;
}

.fav-align {
  display: grid;
  grid-template-columns: auto;
}

.setas {
  width: 10px;
}
@media screen and (max-width: 1000px) {
  .lowlist {
    font-size: 0.815rem;
  }
}
@media (min-width: 700px) {
  .fav-align {
    grid-template-columns: auto auto auto;
  }
}
@media (min-width: 1200px) {
  .fav-align {
    grid-template-columns: auto auto auto auto;
  }
}
@media (min-width: 1500px) {
  .fav-align {
    grid-template-columns: auto auto auto auto auto;
  }
}
