.slide-img {
  height: 50vh;
  width: 80vw;
}
.ws-scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 80px 80px 80px;
}
.top-list {
  display: flex;
}
.top-list > a {
  text-align: center;
  color: #464545;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.top-list > a > figure > img {
  padding: 10px;
  width: 170px;
}

.first-stroke {
  font-family: "Mrs Eaves XL Serif", serif;
  line-height: 32px;
  color: #2f4858;
  margin-top: 45px;
}
.with-stroke {
  display: flex;
  justify-content: center;
  font-family: "Mrs Eaves XL Serif", serif;
  font-size: 28px;
  line-height: 32px;
  color: #2f4858;
  margin-bottom: 30px;
  margin-top: 30px;
}
.bst-seller {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  color: black;
}
.bst-seller > a {
  color: black;
  padding: 0 15px 0 15px;
  font-family: "Mr Eaves XL Modern", sans-serif;
}
.cat-name {
  font-size: 13px;
  line-height: 10px;
}
.review-cont {
  display: flex;
  justify-content: center;
}
.review-each {
  width: 240px;
  margin: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #ebeff1;
  font-family: "Mr Eaves XL Modern";
}
.cont-rev {
  height: 460px;
  overflow-y: hidden;
}
.pic-hover {
  width: 250px;
  margin-right: 10px;
}
.img-nl {
  height: 190px;
}
.bst-img {
  width: 250px;
  height: 190px;
}
.hidden {
  opacity: 0;
  transition: all 1.5s;
  transform: translateX(-100%);
}
.show {
  opacity: 1;
  transform: translateX(0);
}
.supreme {
  background: linear-gradient(#f8f8f8, #f7f3f4);
}
.icon-fav {
  position: absolute;
  display: inline-block;
  color: rgba(206, 205, 198, 0.849);
  background-color: rgba(245, 227, 220, 0.3);
  right: 10px;
  top: 10px;
  padding: 10px;
  border-radius: 25px;
  transition: all 300ms;
}
.icon-fav.active {
  color: rgb(155, 152, 147);
  background-color: rgb(209, 56, 56);
}
.icon-fav:hover {
  transform: scale(1.1);
}
.stroke-head {
  font-size: 20px;
  border-bottom: 1px solid orange;
}
.stroke-body {
  display: flex;
  justify-content: center;
  margin: 40px 0px 40px 0px;
}
.stroke-body1 {
  width: 40vw;
}
.stroke-btn {
  height: 40px;
}
.anchor-footer {
  color: black;
}
.ad-img {
  height: 360px;
  width: 70vw;
}
.cont-category {
  display: flex;
  align-items: center;
  overflow: auto;
}
.best-align {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overflow: hidden;
}
.bstimgsize {
  width: 200px;
  height: 200px;
}
.lefttag {
  width: 20px;
  height: 250px;
  background-color: rgb(208, 212, 198);
}
#set-red:focus {
  background-color: darkred;
}
.slide-pics {
  display: flex;
  align-items: center;
  justify-content: center;
}
.big-differ {
  position: absolute;
  color: white;
}
.shop-now {
  color: white;
  padding: 10px;
  border-radius: 30%;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.formreviewa {
  display: flex;
  flex-direction: column;
  width: 70vw;
  height: 240px;
}
.asdsd {
  display: flex;
  align-items: center;
  justify-content: center;
}
.customerimg {
  width: 120px;
  height: 10px;
}
.formobile {
  display: none;
}
.allstories {
  box-shadow: inset 0 0 0 1px #ed7745;
  background-color: #ffffff;
  color: #ed7745;
  outline: none;
  text-align: center;
  font-family: "Mr Eaves XL Modern", sans-serif;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 0px;
  position: relative;
  text-decoration: none;
  display: inline-block;
  border-color: #5c5c5c;
  transition: background-color 300ms ease-out;
  border-style: solid;
  border-width: 0;
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 3px !important;
  font-size: 16px;
  letter-spacing: 1px;
}
.allstories:hover {
  background-color: #ffffff;
  color: #ed7745;
}
.allstories:focus {
  background-color: #ed7745;
  color: #ffffff;
}
.testitext {
  width: 750px;
  padding: 0 5px;
  margin: 0 10px 20px 30px;
  min-height: 20px;
  line-height: 21px;
  font-family: georgia;
}
.testitext:before {
  content: "\201C";
  color: #ccc;
  position: absolute;
  font-size: 50px;
  line-height: 40px;
  opacity: 0.5;
}
.testitext:after {
  content: "\201D";
  color: #ccc;
  position: absolute;
  font-size: 50px;
  line-height: 40px;
  opacity: 0.5;
}
.setstories {
  border: 1px solid rgba(173, 216, 230, 0.192);
  display: flex;
  justify-content: center;
  padding-top: 10px;
  background-color: rgba(231, 227, 227, 0.1);
}
@media (max-width: 1200px) {
  .ad-img {
    height: max-content;
    width: max-content;
  }
}
@media (max-width: 780px) {
  .formobile {
    display: flex;
    flex-direction: column;
  }
  .fordesktop {
    display: none;
  }
  .allstories {
    font-size: 10px;
  }
}
@media (max-width: 700px) {
  .slide-img {
    height: 32vh;
    width: 100vw;
  }
  .ws-scroll {
    padding: 2px;
    margin: 0;
    overflow-y: scroll;
  }
  .cat-name {
    font-size: 12px;
    line-height: 10px;
  }
  .top-list {
    line-height: 0px;
  }
  .top-list > a > figure > img {
    padding: 5px;
  }
  .ws-scroll {
    padding: 0;
  }
  .review-each {
    margin: 5px;
    padding: 0px;
    border: 1px solid rgb(158, 158, 158);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 12px;
  }
  .testitext {
    width: auto;
    padding: 0 5px;
    margin: 0 0px 20px 0px;
    min-height: 20px;
    line-height: 21px;
    font-family: georgia;
  }
  .cont-rev {
    height: 350px;
    overflow-y: hidden;
  }
  .review-each > h4 {
    font-size: 15px;
  }
  .img-1 {
    font-size: 12px;
  }
  .footer-top {
    display: none;
  }
  .stroke-head {
    font-size: 15px;
    border-bottom: 1px solid orange;
  }
  .stroke-body {
    margin: 10px 0px 10px 0px;
  }
  .stroke-body1 {
    width: 60vw;
  }
  .with-stroke {
    display: flex;
    justify-content: center;
    font-family: "Mrs Eaves XL Serif", serif;
    line-height: 32px;
    color: #2f4858;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  .bst-img {
    width: 150px;
    height: 100px;
  }
  .pic-hover {
    width: 200px;
  }
  .img-nl {
    height: 100px;
  }
  .footer-mid {
    font-size: 12px;
  }
  .anchor-footer {
    font-size: 16px;
  }
  .lefttag {
    width: 17px;
    height: 198px;
  }
  .bstimgsize {
    width: 150px;
    height: 150px;
  }
}
@media (min-width: 700px) {
  .slide-img {
    width: 90vw;
  }
  .bst-container {
    padding: 0px;
  }
}
@media (min-width: 1200px) {
  .slide-img {
    width: 1000px;
  }
  .bst-container {
    padding: 0px 80px 0px 80px;
  }
}
@media (min-width: 1500px) {
  .slide-img {
    width: 1250px;
  }
  .bst-container {
    padding: 0px 80px 0px 80px;
  }
}
