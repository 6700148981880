.cont-1 {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.cont-img {
  position: absolute;
  display: flex;
  justify-content: center;
}
.sofa-head {
  position: relative;
  color: white;
  text-align: center;
}
.imasd {
  height: 400px;
  overflow: hidden;
}
.img-animation {
  width: 95vw;
  height: 400px;
  animation: sofaanimation 7s infinite;
}

.hide {
  display: none;
}
@keyframes sofaanimation {
  0% {
    transform: translateX(-1%) translateY(-1%);
  }
  50% {
    transform: translateX(-2%) translateY(-6%);
  }
  100% {
    transform: translateX(-1%) translateY(-1%);
  }
}
.productItem {
  display: grid;
  grid-template-columns: auto auto auto auto;
  flex-direction: row;
  flex-wrap: nowrap;
}
.productItem * {
  margin: 10px;
}
.productItem *:hover {
  transform: scale(1.03);
}
.popup-img {
  width: 100%;
  max-height: 80vh;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.close-btn {
  width: 40px;
  padding: 0;
  margin: 0;
  border-radius: 200px;
  background-color: rgb(196, 187, 71);
  color: white;
  position: absolute;
  right: -10px;
  top: -10px;
  transition: all 1s;
  transition-property: transform;
  text-align: center;
}
.close-btn:hover {
  transform: scale(1.1);
}
.iconproduct-fav {
  position: absolute;
  display: inline-block;
  color: rgba(255, 255, 255, 0.849);
  background-color: rgb(94, 90, 87);
  right: 35px;
  top: 25px;
  padding: 10px;
  border-radius: 25px;
  transition: all 300ms;
}
.iconproduct-fav.active {
  color: rgb(155, 152, 147);
  background-color: rgb(209, 56, 56);
}
.iconproduct-fav:hover {
  transform: scale(1.2);
}
.item-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 200ms ease-in-out;
  border: none;
  border-radius: 10px;
  z-index: 10;
  width: 700px;
  max-width: 80%;
}
.item-modal-show {
  transform: translate(-50%, -50%) scale(1);
}
.layoutforproduct {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(128, 128, 128, 0.305);
  transition: all 1s;
  pointer-events: all;
}
