.cont-1 {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.cont-img {
  position: absolute;
  display: flex;
  justify-content: center;
}
.sofa-head {
  position: relative;
  color: white;
  text-align: center;
}
.imasd {
  height: 400px;
  overflow: hidden;
}
.img-animation {
  width: 95vw;
  height: 400px;
  animation: sofaanimation 7s infinite;
}
.product-all {
  transition: all 1s;
  filter: blur(4px);
}
.product-details {
  transition: all 1s;
  transform: translateX(40vw) translateY(-50vh);
  transition-property: transform;
  text-align: center;
  position: fixed;

  left: 0;
  top: 80vh;
}
.hide {
  display: none;
}
@keyframes sofaanimation {
  0% {
    transform: translateX(-1%) translateY(-1%);
  }
  50% {
    transform: translateX(-2%) translateY(-6%);
  }
  100% {
    transform: translateX(-1%) translateY(-1%);
  }
}
.productItem {
  display: grid;
  grid-template-columns: auto auto auto auto;
  flex-direction: row;
  flex-wrap: nowrap;
}
.productItem * {
  margin: 10px;
}
.productItem *:hover {
  transform: scale(1.03);
}
.popup-img {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

@media (max-width: 700px) {
  .productItem {
    display: grid;
    grid-template-columns: auto auto;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .cont-1 {
    height: 200px;
    margin-top: 50px;
  }
  .imasd {
    height: 200px;
    width: max-content;
  }
  .img-animation {
    height: 300px;
  }
}

@media (min-width: 950px) {
  .product-details {
    transform: translateX(22vw) translateY(-50vh);
    position: fixed;
    left: 0;
    top: 60vh;
  }
}
@media (min-width: 1200px) {
  .cont-1 {
    width: 900;
  }
  .img-animation {
    width: 900px;
  }
}
@media (min-width: 1500px) {
  .cont-1 {
    width: 1200;
  }
  .img-animation {
    width: 1200px;
  }
}
