.hide {
  display: none;
}
.popshare {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  bottom: 100px;
  background-color: rgba(177, 182, 187, 0.13);
  padding: 5px;
  animation: 1s ease 1 pulse;
  transition: all 1s;
}
.popsharewhat {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0px;
  bottom: 100px;
  background-color: rgba(240, 233, 233, 0);
  padding: 10px;
  animation: 1s ease 1 pulse;
  transition: all 1s;
}
.popsharewhat:hover {
  transform: scale(1.2);
}
@keyframes pulse {
  0% {
    transform: translateX(130px);
  }

  100% {
    transform: translateX(0px);
  }
}
.popshare-end {
  top: -100px;
  left: 115px;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.circle-bg-fb,
.circle-bg-tw,
.circle-bg-g,
.circle-bg-p {
  width: 50px;
  height: 50px;
  display: box;
  color: white;
  border-radius: 50%;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  margin: 5px 0 0 5px;
  z-index: -1;
}
.circle-bg-fb {
  background-color: #3b5998;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.circle-bg-tw {
  background-color: #55acee;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.circle-bg-p {
  background-color: #bd081c;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.circle-bg-g {
  font-size: 30px;
  background-color: #5aca56;
  -webkit-transition: all 1s;
  transition: all 1s;
}
@media (max-width: 500px) {
  .popshare {
    display: none;
  }
  .circle-bg-g {
    width: 55px;
    height: 55px;
    font-size: 43px;
    padding: 5px;
  }
  .popsharewhat {
    bottom: 50px;
  }
}
