.list {
  display: flex;
  width: 100%;
  .listContainer {
    flex: 6;
  }
}
.viewButton {
  margin-right: 10px;
}
