.reviewContainer {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./bgReview.jpg");
  background-repeat: repeat;
  background-size: 120px;
}
.reviewCont1 {
  display: flex;
  padding: 10px;
  margin: 0px 10px;
  background-color: rgba(255, 255, 255, 0.61);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.reviewCont1 > img {
  width: 350px;
  border-radius: 0% 80% 10% 0% / 0% 60% 100% 90%;
}
.reviewCont1 > div {
  width: 500px;
  display: flex;
  margin: 0px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.reviewControl {
  width: 440px;
  margin-bottom: 10px;
}
@media (max-width: 950px) {
  .reviewCont1 > img {
    width: 300px;
  }
  .reviewControl {
    width: 300px;
    margin-bottom: 10px;
  }
}
@media (max-width: 675px) {
  .reviewCont1 > img {
    display: none;
  }
  .reviewCont1 {
    width: 96%;
  }
  .reviewControl {
    width: 300px;
  }
  .reviewCont1 {
    background-color: rgba(255, 255, 255, 0.87);
  }
}
@media (max-width: 430px) {
  .reviewControl {
    width: 300px;
  }
}
