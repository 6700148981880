.product-admin-align {
  display: grid;
  grid-template-columns: auto auto auto;
}

.prod-name {
  font-weight: bold;
  padding: 10px;
  margin: 1px;
  border: 1px solid rgb(216, 209, 209);
  border-radius: 20px;
}
.table {
  width: 90px;
}
